import { render, staticRenderFns } from "./RFEM.vue?vue&type=template&id=e8507b7c&scoped=true&"
import script from "./RFEM.vue?vue&type=script&lang=js&"
export * from "./RFEM.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8507b7c",
  null
  
)

export default component.exports